import React, { useEffect, useState } from 'react';
import { Box, Tab, Tabs, tabsClasses, Typography } from '@mui/material';
import { ProductGroup } from '@/api/product';
import { useRouter } from 'next/router';
import Image from 'next/image';
import SkeletonTabs from '../skeleton/SkeletonTabs';
import { isProduction } from '@/config';
import Grid from '@mui/material/Unstable_Grid2';

interface ProductCategory {
    category_id: number;
    category_icon_small: string;
    category_title: string;
    category_type: string;
}

interface Props {
    setCatId?: any;
}

const TabCategoryProduct: React.FC<Props> = ({ setCatId }) => {
    const router = useRouter();
    const category_id = router.query.category_id as string || "";

    const [category, setCategory] = useState<ProductCategory[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchData = async () => {
        try {
            setIsLoading(true);
            const response = await ProductGroup();
            if (response.success) {
                // Sort response.data by category_type, prioritizing "service" first
                const sortedData = response.data.sort((a, b) => {
                    // Move "Service" categories to the top
                    if (a.category_type === "Service" && b.category_type !== "Service") return -1;
                    if (a.category_type !== "Service" && b.category_type === "Service") return 1;
                
                    // Within "Service" and "Product", sort by category_id (smallest first)
                    return a.category_id - b.category_id;
                });
    
                // console.log(JSON.stringify(sortedData, null, 2));
                setCategory(sortedData);
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        // Split the newValue by the hyphen and extract the second part (category_type)
        const [categoryId, categoryType] = newValue.split('-');
        
        // resetLimit();
        ["offset", "limit", "scrollPosition"].forEach((key) => sessionStorage.removeItem(key));
        // Optionally fetch data for the new category
        // setOffsetMore(0); // Reset offset to 0
        // fetchDataMore(0, false, getResponsiveLimit(), categoryType);
        if (setCatId) {
            setCatId(Number(categoryId));
        }
        // Filter matching category
        const categoryArray = Object.values(category);
        const categoryItems: any = categoryArray.map((item, idx) => {
            return {
                item_id: item.category_id,
                item_name: item.category_title,
                item_type: item.category_type,
                index: idx,
            }
        })
        const filteredData = categoryItems.filter((cate: any) => cate.item_id === Number(categoryId));

        if (filteredData && filteredData.length > 0) {

            // Update dataLayer for Google Analytics
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({ ecommerce: null }); // Clear previous ecommerce data
            window.dataLayer.push({
                event: "select_category",
                ecommerce: {
                    category_id: filteredData[0]?.item_id,
                    category_name: filteredData[0]?.item_name,
                    category_type: filteredData[0]?.item_type,
                    // items: filteredData,
                },
            });

            // Log data in non-production environments
            if (!isProduction) {
                // console.log("DataLayer updated:", {
                //     event: "select_category",
                //     ecommerce: {
                //         category_id: filteredData[0]?.item_id,
                //         category_name: filteredData[0]?.item_name,
                //         category_type: filteredData[0]?.item_type,
                //         // items: filteredData,
                //     },
                // });
            }

        } else {
            // Handle the case when filteredData is empty or undefined
            if (!isProduction) {
                console.warn("No matching category data to push to dataLayer.");
            }
        }

        const currentPath = '/search/allsearch';
        const currentQuery = {
            ...router.query,
            category_id: categoryId, // Use categoryId in query
            keyword: router.query.keyword || '',
            product_type: categoryType // Use categoryType in query 
        };

        router.push({
            pathname: currentPath,
            query: currentQuery,
        }, undefined, { shallow: true });
    };

    return (
        <Grid container my={1}>
            {category.length > 0 && (
                <>
                    {!isLoading ? (
                        <Grid sx={{ display: 'flex', justifyContent: 'center' }} xs={12}>
                            <Tabs
                                // value={category_id}
                                onChange={handleChange}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable product category tabs"
                                allowScrollButtonsMobile
                                TabIndicatorProps={{
                                    style: { backgroundColor: 'white' },
                                }}
                                sx={{
                                    [`& .${tabsClasses.scrollButtons}`]: {
                                        '&.Mui-disabled': { opacity: 0.3 }, width: "20px"
                                    },
                                    flexWrap: 'nowrap',
                                    "& .Mui-selected .tab-content": {
                                        color: '#292929',
                                    },
                                }}
                            >
                                {category.map((data: ProductCategory) => (
                                    <Tab
                                        disableRipple
                                        key={data.category_id}
                                        style={{ padding: "15px 10px" }}
                                        label={
                                            <Box
                                                className="tab-content"
                                                sx={{
                                                    color: '#717171',
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    textDecoration: 'none',
                                                    whiteSpace: 'nowrap',
                                                    flexShrink: 0,
                                                    // borderBottom: data.category_id === Number(category_id) && "2px solid #000000"
                                                }}
                                                // onClick={() => setCatId?(data.category_id):undefined}
                                                onClick={() => setCatId(Number(data.category_id))}
                                            >
                                                <Image
                                                    src={data.category_icon_small}
                                                    alt={`ไอคอนสำหรับหมวดหมู่ ${data.category_title}`}
                                                    width={30}
                                                    height={30}
                                                    layout="fixed"
                                                    objectFit="contain"
                                                    priority
                                                    // loading="lazy"
                                                    placeholder="empty"
                                                    blurDataURL={data.category_icon_small}
                                                />
                                                <Typography
                                                    variant="h3"
                                                    sx={{
                                                        mt: 1,
                                                        fontSize: { xs: 12, lg: 13 },
                                                        '&:hover': { color: '#000000' },
                                                        color: data.category_id === Number(category_id) ? '#000000' : '#717171'
                                                    }}
                                                >
                                                    {data.category_title}
                                                </Typography>
                                            </Box>
                                        }
                                        value={`${data.category_id}-${data.category_type}`}
                                        sx={{
                                            flexShrink: 0, padding: 1.5
                                        }}
                                    />
                                ))}
                            </Tabs>
                        </Grid>
                    ) : (
                        <Grid xs={12} my={4}>
                            <SkeletonTabs />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

export default TabCategoryProduct;
